.card_item {
	width: 325px;
	height: 550px;
	margin: 0 10px;
	display: flex;
	align-items: center;
}
.card {
	margin: 0 auto;
	width: 310px;
	height: 530px;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	border-radius: 2px;
	-webkit-border-radius: 2px;
	-moz-border-radius: 2px;
	-ms-border-radius: 2px;
	-o-border-radius: 2px;
	box-shadow: 0px 1px 50px rgba(255, 255, 255, 0.062);
	border-bottom: #d4d4d4 1px solid;
	transition: 0.5s ease-in-out;
	-webkit-transition: 0.5s ease-in-out;
	-moz-transition: 0.5s ease-in-out;
	-ms-transition: 0.5s ease-in-out;
	-o-transition: 0.5s ease-in-out;
	transform: scale(1);
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-ms-transform: scale(1);
	-o-transform: scale(1);
}
.card:hover {
	border: 0.5px solid #d4d4d4;
	transform: scale(1.01);
	transition: 0.5s ease-in-out;
	-webkit-transition: 0.5s ease-in-out;
	-moz-transition: 0.5s ease-in-out;
	-ms-transition: 0.5s ease-in-out;
	-o-transition: 0.5s ease-in-out;
	-webkit-transform: scale(1.01);
	-moz-transform: scale(1.01);
	-ms-transform: scale(1.01);
	-o-transform: scale(1.01);
}
/* image*/
.card_img {
	width: 100%;
	height: 75%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.picture {
	z-index: 9;
	border-radius: 2px;
	width: 100%;
	height: 100%;
	-webkit-border-radius: 2px;
	-moz-border-radius: 2px;
	-ms-border-radius: 2px;
	-o-border-radius: 2px;
}
.link_card {
	width: 100%;
	height: 100%;
	text-decoration: none;
}

/* sizes */
.size_cnt_list {
	z-index: 10;
	position: absolute;
	background-color: #ffffffcb;
	backdrop-filter: blur(5px);
	bottom: 0;
	width: 100%;
	height: 25%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	border-radius: 1px;
	transition: 1s ease;
	-webkit-transition: 1s ease;
	-moz-transition: 1s ease;
	-ms-transition: 1s ease;
	-o-transition: 1s ease;
	-webkit-border-radius: 1px;
	-moz-border-radius: 1px;
	-ms-border-radius: 1px;
	-o-border-radius: 1px;
}
.sizes_open {
	bottom: 132px;
	transition: 1s ease;
	-webkit-transition: 1s ease;
	-moz-transition: 1s ease;
	-ms-transition: 1s ease;
	-o-transition: 1s ease;
}

.box_stock {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	padding: 5px;
	font-weight: 100;
	width: 80%;
	height: 55%;
	color: #85858583;
	user-select: none;
}
.disabled_size {
	margin: 5px;
	width: 24px;
	height: 24px;
	background-color: #fff;
	display: inline-block;
	display: flex;
	justify-content: center;
	align-items: center;
	pointer-events: none;
	border-radius: 50%;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	-ms-border-radius: 50%;
	-o-border-radius: 50%;
}

.stock {
	width: 24px;
	height: 24px;
	margin: 5px;
	color: #121212;
	display: inline-block;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #fff;
	cursor: pointer;
	border-radius: 50%;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	-ms-border-radius: 50%;
	-o-border-radius: 50%;
	transition: 2s ease;
	-webkit-transition: 2s ease;
	-moz-transition: 2s ease;
	-ms-transition: 2s ease;
	-o-transition: 2s ease;
}
.selected_size {
	transition: 2s ease;
	color: #00a698;
	-webkit-transition: 2s ease;
	-moz-transition: 2s ease;
	-ms-transition: 2s ease;
	-o-transition: 2s ease;
}
.btn_cnt_product {
	height: 45%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.btn_car_add_product {
	font-size: 12px;
	margin-left: 0;
	height: 35px;
}

/* .no_products {
	margin-top: 50px;
	color: #121212;
	text-align: center;
} */

/* body card */
.card_body {
	z-index: 11;
	background-color: whitesmoke;
	height: 25%;
	padding: 5px 0;
	width: 100%;
}

.card_text_box {
	width: 95%;
	display: flex;
	justify-content: space-between;
	margin: 10px;
	color: #121212;
}
.card_text {
	display: inline-block;
	margin: 0;
}
.link_card_title {
	width: auto;
}
.card_title {
	font-size: 14px;
}

.card_price {
	font-weight: bolder;
	font-size: 14px;
}

.card_btns {
	display: flex;
	padding: 15px 0;
	justify-content: space-evenly;
}

.btn_card {
	font-size: 12px;
	font-weight: 500;
}
.btn_card_primary {
	border: none;
	margin-left: 5px;
	font-weight: 500;
	background: #987d2c;
	color: whitesmoke;
}
.btn_card_secondary {
	margin-right: 5px;
}
