.form_main_container {
	background-color: whitesmoke;
	margin-top: 110px;
	height: 700px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.form_box {
	background: #121212;
	height: 400px;
	width: 437px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border: 2px solid #222;
	border-radius: 5px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	-ms-border-radius: 5px;
	-o-border-radius: 5px;
	box-shadow: 0px 1px 50px #1212122a;
}
.register {
	height: 600px;
}

.form_ctn_box {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 10px 0;
}
.form_title_box {
	display: flex;
	justify-content: center;
	margin-bottom: 20px;
}

.form_title {
	font-size: 48px;
	font-weight: 100;
	margin: 0;
}

.form_body {
	display: flex;
	justify-content: center;
}

.login_form {
	display: flex;
	flex-direction: column;
}

input {
	background: none;
	font-size: 16px;
	color: #cacaca;
	margin-top: 20px;
	height: 25px;
	border: none;
	border-bottom: 3px solid #00a698;
	outline: none;
	margin-bottom: 10px;
}
.errors_text {
	color: #cacaca;
	font-size: 12px;
}

.btn_form {
	background: inherit;
	font-size: 16px;
	font-weight: 500;
	color: #00a698;
	margin: 30px 0;
	width: 110px;
	height: 45px;
	display: flex;
}
