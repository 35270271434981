.verify_box {
	width: 500px;
	height: 300px;
	background: #121212;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border: 2px solid #222;
	border-radius: 2px;
	-webkit-border-radius: 2px;
	-moz-border-radius: 2px;
	-ms-border-radius: 2px;
	-o-border-radius: 2px;
	box-shadow: 0px 1px 50px #1212122a;
}

.verify_ctn_box {
	height: 80%;
	display: flex;
	flex-direction: column;
	margin: 30px 30px 10px 30px;
}
.verify_title_box {
	margin-bottom: 20px;
}

.verify_title {
	font-size: 38px;
	font-weight: 100;
	margin: 0;
	text-align: center;
}
.verify_body {
	margin-top: 10px;
	display: flex;
	flex-direction: column;
	text-align: center;
	align-items: center;
}
.btn_verify {
	margin-top: 15px;
}
