.slider_container {
	position: relative;
	height: 100%;
	width: 100%;
	/* display: flex;
	justify-content: center;
	align-items: center; */
}

/* .box_images {
	opacity: 0;
	transition-duration: 1s ease;
}

.active_img {
	opacity: 1;
	transition-duration: 1s;
	transform: scale(1.1);
	-webkit-transform: scale(1.1);
	-moz-transform: scale(1.1);
	-ms-transform: scale(1.1);
	-o-transform: scale(1.1);
} */

.image {
	width: 100%;
	height: 600px;
	/* border-radius: 5px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	-ms-border-radius: 5px;
	-o-border-radius: 5px; */
	/* vertical-align: top; */
	object-fit: cover;
	object-position: center;
}
/* 
.left_arrow {
	position: absolute;
	top: 50%;
	left: 30px;
	cursor: pointer;
	z-index: 995;
	user-select: none;
	transition: 0.5s ease;
	-webkit-transition: 0.5s ease;
	-moz-transition: 0.5s ease;
	-ms-transition: 0.5s ease;
	-o-transition: 0.5s ease;
}

.left_arrow:hover {
	transition: 0.5s;
	transform: scale(1.2);
	-webkit-transform: scale(1.2);
	-moz-transform: scale(1.2);
	-ms-transform: scale(1.2);
	-o-transform: scale(1.2);
	-webkit-transition: 0.5s;
	-moz-transition: 0.5s;
	-ms-transition: 0.5s;
	-o-transition: 0.5s;
}

.right_arrow {
	position: absolute;
	top: 50%;
	right: 30px;
	cursor: pointer;
	z-index: 995;
	user-select: none;
	transition: 0.5s ease;
	-webkit-transition: 0.5s ease;
	-moz-transition: 0.5s ease;
	-ms-transition: 0.5s ease;
	-o-transition: 0.5s ease;
}

.right_arrow:hover {
	transition: 0.5s;
	transform: scale(1.2);
	-webkit-transform: scale(1.2);
	-moz-transform: scale(1.2);
	-ms-transform: scale(1.2);
	-o-transform: scale(1.2);
	-webkit-transition: 0.5s;
	-moz-transition: 0.5s;
	-ms-transition: 0.5s;
	-o-transition: 0.5s;
} */
