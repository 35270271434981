.dropdown_cart {
	position: absolute;
	width: 400px;
	height: 100vh;
	top: 110px;
	right: -400px;
	background: #fff;
	box-shadow: 0px 4px 10px #0505051a;
	border-radius: 2px;
	-webkit-border-radius: 2px;
	-moz-border-radius: 2px;
	-ms-border-radius: 2px;
	-o-border-radius: 2px;
	transition: 1s ease;
	-webkit-transition: 1s ease;
	-moz-transition: 1s ease;
	-ms-transition: 1s ease;
	-o-transition: 1s ease;
}

.cart_active {
	right: 0;
	transition: 1s ease;
	-webkit-transition: 1s ease;
	-moz-transition: 1s ease;
	-ms-transition: 1s ease;
	-o-transition: 1s ease;
}

.container_cart_menu {
	width: 100%;
	height: 100%;
}
.title_cnt_cart_menu {
	display: flex;
	justify-content: center;
}
.title_cart_menu {
	color: #121212;
	font-weight: 700;
	margin-top: 20px;
	margin-bottom: 0;
}
.hr_cartbox {
	display: flex;
	justify-content: center;
	height: 0;
	margin: 0;
	margin: 20px 0;
}

.hr_cart {
	width: 70%;
	border-top: 1px solid #cacaca;
}
.subtitles_cartbox {
	display: flex;
	justify-content: center;
	color: #2c2c2c;
}
.subtitles_cart {
	margin-bottom: 10px;
	width: 90%;
	display: flex;
	justify-content: space-between;
}
.subtitle_cart {
	margin: 0;
	font-weight: 500;
}
.subt_cart_product {
	width: 35%;
}

.box_cnt_cart_menu {
	height: 60%;
}

.no_products {
	text-align: center;
	color: #444;
}

/*items*/
.cnt_items_cart_menu {
	height: 75%;
	color: #121212;
	margin: 5px 0;
	padding: 0 10px;
	overflow: auto;
}
.box_items_cart_menu {
}

.item_cart_menu {
	position: relative;
	height: 80px;
	border-top: #121212 solid 1px;
	margin-bottom: 10px;
	padding-top: 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 14px;
	font-weight: 100;
}

.btn_delete_cart {
	position: absolute;
	right: 0;
	top: 10px;
	cursor: pointer;
}

.photo_min {
	width: 40px;
	height: 60px;
	object-fit: cover;
	display: flex;
	align-items: center;
}

.img_min {
	width: 100%;
	height: 100%;
}

.box_text_cart {
	width: 33%;
	height: 66%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
}

.text_cart_item {
	font-size: 14px;
	font-weight: 100;
}

.price_cart_item {
	font-size: 12px;
	color: #cacacae7;
}
.box_sizes_cart_item {
	width: 10%;
}

.box_quantity_cart {
	display: flex;
	width: 18%;
	align-items: center;
	justify-content: space-between;
}
.btn_counter_cart {
	height: 25px;
	width: 25px;
}
.box_subprice_cart {
	width: 18%;
}

.total_cart {
	margin: 0;
}

/*checkout*/
.cnt_adress_cart_menu {
	height: 20%;
	color: #121212;
	text-align: center;
}

.box_adress_cart_menu {
	margin: 20px 0;
}

.checkbox_shipping {
	margin: 20px 0;
	padding: 0 20px;
	display: flex;
	align-items: center;
}
.checkbox_leyend {
	font-size: 12px;
	cursor: pointer;
	color: #00a650;
}
.checkbox_leyend_price {
	color: #222;
}

.checkbox_input {
	cursor: pointer;
	margin: 0 10px;
	width: 18px;
	height: 18px;
}
.checkbox_input:checked {
	background: #00a698;
}

.checkout_cart {
	width: 70%;
}
.btn_center_box {
	display: flex;
	margin-top: 25px;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.btn_disabled {
	cursor: default;

	/* for "disabled" effect */
	opacity: 0.5;
	background: #ccc;
}
.disabled_event {
	/* for disabled action js */
	pointer-events: none;
	cursor: pointer;
}
