/*products page*/
.products_main_container {
	background: whitesmoke;
	margin-top: 110px;
	height: auto;
	display: flex;
	justify-content: center;
}

/*product list */
.products_container {
	width: 1200px;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
}

.products_cards_box {
	margin: 40px 0;
	width: 100%;
	height: auto;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	padding: 0 10px;
}
.product_section {
	width: 90%;
}

.empty {
	height: 1000px;
}

.cnt_products {
	display: flex;
	width: 100%;
	height: auto;
	justify-content: center;
}
.category_aside {
	width: 10%;
	height: auto;
	margin: 40px 0;
	display: flex;
	justify-content: center;
	padding: 0 10px;
	color: black;
}
.container_category {
}
.category_title {
	text-align: center;
	font-size: 22px;
	font-weight: 500;
	margin-top: 0;
}
.categori_list {
	padding: 0;
}
.category_item_list {
	list-style: none;
	margin-bottom: 10px;
	font-size: 16px;
}
.category_item_link {
	font-weight: 100;
	color: #222;
}
