@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');
body {
	margin: 0 auto;
	padding: 0;
	width: 100%;
	font-family: 'DM Sans', sans-serif, 'Roboto', 'Oxygen', -apple-system,
		'Segoe UI', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
		'Helvetica Neue';
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: #121212;
	color: whitesmoke;
}

.btn_primary {
	border: none;
	cursor: pointer;
	color: #121212;
	font-weight: 700;
	width: 90px;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 2px;
	-webkit-border-radius: 2px;
	-moz-border-radius: 2px;
	-ms-border-radius: 2px;
	-o-border-radius: 2px;
	transition: 0.5s ease all;
	-webkit-transition: 0.5s ease all;
	-moz-transition: 0.5s ease all;
	-ms-transition: 0.5s ease all;
	-o-transition: 0.5s ease all;
}

.btn_primary:hover {
	transition: 0.5s ease all;
	color: #121212;
	-webkit-transition: 0.5s ease all;
	-moz-transition: 0.5s ease all;
	-ms-transition: 0.5s ease all;
	-o-transition: 0.5s ease all;
}

.btn_secondary {
	background: inherit;
	color: #00a698;
	width: 90px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	cursor: pointer;
	border: 1px solid #00a698;
	border-radius: 2px;
	transition: 0.5s ease all;
	-webkit-transition: 0.5s ease all;
	-moz-transition: 0.5s ease all;
	-ms-transition: 0.5s ease all;
	-o-transition: 0.5s ease all;
	-webkit-border-radius: 2px;
	-moz-border-radius: 2px;
	-ms-border-radius: 2px;
	-o-border-radius: 2px;
}
.btn_secondary:hover {
	transition: 0.5s ease all;
	background: #00a698;
	color: #121212;
	-webkit-transition: 0.5s ease all;
	-moz-transition: 0.5s ease all;
	-ms-transition: 0.5s ease all;
	-o-transition: 0.5s ease all;
}

.hidden {
	opacity: 0;
}

.visible {
	opacity: 1;
	transition: 0.5s ease-in;
	-webkit-transition: 0.5s ease-in;
	-moz-transition: 0.5s ease-in;
	-ms-transition: 0.5s ease-in;
	-o-transition: 0.5s ease-in;
}
