.bread_container {
	width: 100%;
	margin-bottom: 50px;
	padding-left: 5px;
	display: flex;
	align-items: center;
}
.bread_items {
	margin-right: 10px;
	color: #555;
	font-size: 12px;
}
.bread_a {
	font-weight: 700;
}
.bread_status_on {
	font-weight: 100;
	font-size: 12px;
	color: #121212;
	border-bottom: 1px solid #ceaf70;
}
