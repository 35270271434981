.hr {
	width: 100%;
	margin-bottom: 72px;
	border-top: solid 2px #222;
}

.container_product_cards {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-bottom: 55px;
}

.text_center {
	display: flex;
	justify-content: center;
	margin: 0;
}

.products_cards_title {
	color: #121212;
	border-bottom: solid 2px #22bbae;
	font-size: 36px;
	font-weight: 100;
}

.cnt_cards {
	width: 90%;
	height: 650px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.box_product_cards {
	padding-top: 40px;
	width: auto;
	height: 600px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.cnt_more_products {
	width: 85%;
	display: flex;
	justify-content: center;
}

.btn_box {
	width: 85%;
	display: flex;
	justify-content: flex-end;
}
.btn_sale_box {
	width: 100px;
	height: 41px;
}
a {
	text-decoration: none;
	color: inherit;
	font-weight: 500;
}

/* banners*/

.container_access_link {
	margin-bottom: 30px;
	width: auto;
	height: 350px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #121212;
}

.box_access_link {
	width: 80%;
	height: 70%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.banners_access_link {
	width: 95%;
	height: 80%;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
}

.access_link {
	width: 30%;
	height: 85%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.access_link_img1 {
	width: 100%;
	height: 100%;
	background-image: url('./../../assets/bence-balla-schottner.jpg');
	background-repeat: no-repeat;
	background-size: cover;
}
.access_link_img2 {
	width: 100%;
	height: 100%;
	background-image: url('./../../assets/jakub-balon.jpg');
	background-repeat: no-repeat;
	background-size: cover;
}
.access_link_img3 {
	width: 100%;
	height: 100%;
	background-image: url('./../../assets/philip-wahl.jpg');
	background-repeat: no-repeat;
	background-size: cover;
}
.img_link_box {
	display: flex;
	align-items: center;
	justify-content: center;
}

.banner_link_title {
	font-size: 38px;
}
