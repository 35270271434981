.welcome_box {
	display: flex;
	/* justify-content: center; */
	width: 100%;
	padding-top: 60px;
	z-index: 30;
}

.welcome_body {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.title {
	display: flex;
	flex-direction: column;
	font-size: 4rem;
	color: #fff;
	text-align: center;
	font-style: italic;
	font-weight: 100;
	user-select: none;
}

.text_about {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 190px;
}

.brake {
	/* margin-top: 48px; */
	/* width: 100vw; */
	height: 4px;
	background: #ceaf70;
}

.body_desciption {
	margin: 5px 0 25px 0;
	font-weight: 400;
	font-size: 15px;
	color: #aaaaaa;
	width: 750px;
	text-align: center;
	height: auto;
}

.btn_products {
	font-size: 1rem;
	width: 140px;
	height: 45px;
}

.welcome_images {
	width: 100%;
	/* padding-left: 20px; */
	position: absolute;
	z-index: 20;
}
