.header_container {
	width: 100%;
	height: 110px;
	display: flex;
	justify-content: center;
	background-color: #121212;
	position: fixed;
	top: 0px;
	left: 0px;
	transition: all 0.5s ease;
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	z-index: 999;
}
.active {
	backdrop-filter: saturate(180%) blur(10px);
	background-color: #121212e3;
	box-shadow: 0px 10px 36px rgba(255, 255, 255, 0.185);
}
.nav_items {
	margin: 0 40px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.nav_box1 {
	width: 85%;
	height: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}

.nav_logo {
	display: flex;
	justify-content: center;
	align-items: center;
}

.nav_logo > img {
	width: 155px;
	height: 35px;
}
.nav_logo :hover {
	cursor: pointer;
}

.nav_menu {
	width: 80%;
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
}

.nav_status {
	color: whitesmoke;
	transition: 500ms ease;
	-webkit-transition: 500ms ease;
	-moz-transition: 500ms ease;
	-ms-transition: 500ms ease;
	-o-transition: 500ms ease;
}
.nav_status:hover {
	color: #606060;
	transition: 500ms ease;
	-webkit-transition: 500ms ease;
	-moz-transition: 500ms ease;
	-ms-transition: 500ms ease;
	-o-transition: 500ms ease;
}

.nav_box2 {
	width: 15%;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
}

.box_items {
	display: flex;
	width: auto;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.nav_user,
.nav_cart {
	margin-right: 20px;
	cursor: pointer;
	transform: scale(1.1);
	-webkit-transform: scale(1.1);
	-moz-transform: scale(1.1);
	-ms-transform: scale(1.1);
	-o-transform: scale(1.1);
	transition-duration: 200ms;
}
.counter_nav {
	position: relative;
}

.box_counter_items {
	position: absolute;
	top: -10px;
	right: -10px;
	font-size: 12px;
	font-weight: 500;
	text-align: center;
	color: #fff;
	background-color: #f14343;
	width: 15px;
	height: 15px;
	border-radius: 100%;
	-webkit-border-radius: 100%;
	-moz-border-radius: 100%;
	-ms-border-radius: 100%;
	-o-border-radius: 100%;
}

.nav_user:active,
.nav_cart:active {
	transform: scale(0.9);
	-webkit-transform: scale(0.9);
	-moz-transform: scale(0.9);
	-ms-transform: scale(0.9);
	-o-transform: scale(0.9);
	transition-duration: 200ms;
}

.nav_cart {
	cursor: pointer;
}
