.container_singleProduct {
	width: 1200px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 40px 0;
}

.box_singleProduct {
	width: 100%;
	display: flex;
	/* align-items: center; */
}
.container_left_images {
	width: 55%;
	display: flex;
}

.images_box {
	width: 20%;
	display: flex;
	flex-direction: column;
	align-items: center;
}
/*size image*/
.options_images {
	margin: 0 10px 10px 0px;
	width: 115px;
	height: 170px;
	object-fit: cover;
	cursor: pointer;
	opacity: 0.7;
	transition: 0.5s all;
	-webkit-transition: 0.5s all;
	-moz-transition: 0.5s all;
	-ms-transition: 0.5s all;
	-o-transition: 0.5s all;
	border-radius: 2px;
	-webkit-border-radius: 2px;
	-moz-border-radius: 2px;
	-ms-border-radius: 2px;
	-o-border-radius: 2px;
}
.options_images:hover {
	opacity: 1;
	transition: 0.5s all;
	-webkit-transition: 0.5s all;
	-moz-transition: 0.5s all;
	-ms-transition: 0.5s all;
	-o-transition: 0.5s all;
}
.active_selected_img {
	border: 1px solid #121212;
	border-radius: 2px;
	opacity: 1;
	transition: 0.5s all;
	-webkit-transition: 0.5s all;
	-moz-transition: 0.5s all;
	-ms-transition: 0.5s all;
	-o-transition: 0.5s all;
	-webkit-border-radius: 2px;
	-moz-border-radius: 2px;
	-ms-border-radius: 2px;
	-o-border-radius: 2px;
}
.selected_image {
	width: 80%;
	display: flex;
	align-items: center;
}
/*size image*/
.main_image {
	width: 565px;
	height: 690px;
	object-fit: contain;
}

.container_right_info_product {
	width: 45%;
	display: flex;
}
.box_details_product {
	width: 100%;
	padding-left: 10px;
	display: flex;
	flex-direction: column;
}
.header_singleProduct {
	display: flex;
	flex-direction: column;
}
.box_title_sp {
	border-bottom: 1px solid #7c7c7c;
}
.title_sp {
	color: #121212;
	margin-top: 0;
	font-size: 28px;
}
.details_sp {
	color: #444;
	margin-top: 10px;
	font-weight: 500;
	font-size: 14px;
}
.price_sp {
	color: #121212;
	font-size: 18px;
}

/* sizes select*/
.form_singleProduct {
	display: flex;
	flex-direction: column;
}
.form_sp {
	border-bottom: 1px solid #7c7c7c;
	margin-bottom: 10px;
}

.selectSize_label {
	color: #ceaf70;
	font-size: 12px;
	margin-bottom: 10px;
}
.selectSize_input {
	display: block;
	height: 30px;
	width: 175px;
	font-size: 14px;
	padding-left: 2px;
	border-radius: 2px;
	-webkit-border-radius: 2px;
	-moz-border-radius: 2px;
	-ms-border-radius: 2px;
	-o-border-radius: 2px;
}
.option_value_disabled {
	color: #cccbcb;
}

.box_btn_sp {
	margin: 20px 0;
}

.btn_car_add_sp {
	font-size: 16px;
	height: 40px;
	background: #987d2c;
	font-weight: 500;
	color: whitesmoke;
	width: 175px;
}
.shipping_description_title {
	color: #121212;
	font-size: 18px;
	margin: 8px 0;
}
.shipping_description_info {
	color: #444;
	font-size: 12px;
	margin-top: 8px;
}
.subtitle_h5 {
	color: #666;
	font-size: 12px;
}

.title_sp_shippng {
	font-size: 12px;
	font-weight: 700;
	color: #666;
	margin-top: 12px;
	margin-bottom: 5px;
}
.box_select_sp {
	display: flex;
}
.message_description_info_sp {
	color: #666;
	font-size: 12px;
	font-weight: 700;
	margin: 6px;
	transition: 1s linear;
	-webkit-transition: 1s linear;
	-moz-transition: 1s linear;
	-ms-transition: 1s linear;
	-o-transition: 1s linear;
}
.hidden_stock_msg {
	opacity: 0;
	transition: 1s linear;
	-webkit-transition: 1s linear;
	-moz-transition: 1s linear;
	-ms-transition: 1s linear;
	-o-transition: 1s linear;
}
