.main_container {
	min-height: 100%;
	display: block;
	position: absolute;
	width: 100%;
}

.welcome_container {
	margin-top: 110px;
	display: flex;
	justify-content: center;
	height: 790px;
}

.productsSale_container {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	background: whitesmoke;
}

.carrousel_container {
}
