.dropdown {
	position: absolute;
	width: 170px;
	top: 110px;
	right: -170px;
	background: #fff;
	box-shadow: 0px 4px 10px #0505051a;
	border-radius: 2px;
	-webkit-border-radius: 2px;
	-moz-border-radius: 2px;
	-ms-border-radius: 2px;
	-o-border-radius: 2px;
	transition: 1s ease;
	-webkit-transition: 1s ease;
	-moz-transition: 1s ease;
	-ms-transition: 1s ease;
	-o-transition: 1s ease;
}

.user_active {
	right: 0px;
	transition: 1s ease;
	-webkit-transition: 1s ease;
	-moz-transition: 1s ease;
	-ms-transition: 1s ease;
	-o-transition: 1s ease;
}

.menu_item {
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #050505;
	text-decoration: none;
	transition: 0.5s ease-in-out;
	-webkit-transition: 0.3s ease-in-out;
	-moz-transition: 0.3s ease-in-out;
	-ms-transition: 0.3s ease-in-out;
	-o-transition: 0.3s ease-in-out;
}

.menu_item:hover {
	color: #cacaca;
	transition: 0.3s all ease;
	-webkit-transition: 0.3s all ease;
	-moz-transition: 0.3s all ease;
	-ms-transition: 0.3s all ease;
	-o-transition: 0.3s all ease;
}
